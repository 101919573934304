import { useEffect } from "react";
import HubspotForm from "react-hubspot-form";

export function ContactHubspot() {
  return (
    <div id="contact" className="section alternate-section">
      <div className="container">
        <div className="margin-bottom-30">
          <div className="row text-center">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2 className="font-weight-normal">Get In Touch</h2>
              <p>
                Send us a message and one of our consultants will get back to
                you.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 text-right mx-auto">
            <HubspotForm
              portalId="48871364"
              formId="11c0ae06-0e65-45b8-81d9-4f43c5f4b7df"
              loading={<div>Loading...</div>}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
